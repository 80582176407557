.logo {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 399.879px;
    height: 93.4646px;
    transform: translate(113.5401px, -5.8px);
}

.textprimary {
    font-size: 60px;
    font-weight: 700;
    transform: translate(17px, 0.746667px);
    color: #212938;
    border-radius: 5px;
}

.textprimarys {
    font-size: 60px;
    font-weight: 700;
    transform: translate(41px, -30.746667px);
    color: #212938;
    border-radius: 5px;
}

.text-info {
    font-size: 2.5rem;
    font-weight: bold;
}

.OfficeSpace {
    width: 100%;
    height: 437.651px;
    transform: translate(-59.5401px, -5.8px);
    border-radius: 10px;
}

.requirement-li {
    font-size: large;
    margin-left: 1px;
}

.requirement-head {
    border-left: 5px solid rgb(1, 42, 45);
}

.textprimary-clientname {
    transform: translate(72px, -20.253333px);

}

.download-pdf-btn {
    background-color: #012a2d;
    color: white !important;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-family: "Calibre", sans-serif !important;
    margin: 16px 0;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.download-pdf-btn:hover {
    background-color: #435254;
}

/* Responsive margin for office proposal container */
.office-proposal-container {
    margin-top: 50px; /* Default margin for mobile */
}

/* Desktop margin */
@media screen and (min-width: 769px) {
    .office-proposal-container {
        margin-top: 120px;
    }
}

/*
@media (max-width: 1220px) {
    .OfficeSpace {
        width: 710px;
        height: 478.651px;
        transform: translate(-11.5401px, -5.8px);
        border-radius: 10px;
    }

    .logo {
        display: flex;
        justify-content: start;
        align-items: start;
        width: 399.879px;
        height: 93.4646px;
        transform: translate(78.5401px, -5.8px);
    }

    .officeproposal {
        padding: 0;
    }

    .textprimary-clientname {
        transform: translate(42px, -20.253333px);

    }
}





@media (max-width: 426px) {

    .requirement-head {
        border-left: 10px solid #2193B0;
        margin-bottom: 102px;
    }

    .OfficeSpace {
        width: 300px;
        height: 264.651px;
        border-radius: 10px;
        margin-left: 10px;
    }
}
    */