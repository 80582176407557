.mobile-navigation {
  display: none;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  padding: 5px;
  color: #435254;
  cursor: pointer;
  font-size: 12px;
  transition: color 0.2s ease;
}

.nav-item:hover {
  color: #012a2d;
}

.nav-icon {
  font-size: 18px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon svg {
  width: 20px;
  height: 20px;
}

.nav-label {
  font-size: 10px;
  font-family: "Calibre", sans-serif;
}

/* Show navigation ONLY on mobile devices */
@media screen and (max-width: 768px) {
  .mobile-navigation {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    justify-content: space-around;
    align-items: center;
    height: 60px;
  }

  /* Add padding to main content to prevent overlap with navigation */
  body {
    padding-bottom: 60px;
  }
}
